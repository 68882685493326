import PasswordForgotPage from "@/views/Auth/PasswordForgotPage.vue"
import PasswordResetPage from "@/views/Auth/PasswordResetPage.vue"
import PasswordSentPage from "@/views/Auth/PasswordSentPage.vue"
import VerifyEmailPage from "@/views/Auth/VerifyEmailPage.vue"
import FinancesPage from "@/views/Finances/FinancesPage.vue"
import FinancesReferralsPage from "@/views/Finances/FinancesReferralsPage.vue"
import FinancesInvitationsPage from "@/views/Finances/FinancesInvitationsPage.vue"
import FinancesStatisticsPage from "@/views/Finances/FinancesStatisticsPage.vue"
import InvitationsPage from "@/views/Finances/InvitationsPage.vue"
import HelpDetailPage from "@/views/HelpDetailPage.vue"
import HelpPage from "@/views/HelpPage.vue"
import HomePage from "@/views/HomePage.vue"
import LoginPage from "@/views/Auth/LoginPage.vue"
import MarketplacesPage from "@/views/MarketplacesPage.vue"
import OfferPage from "@/views/Info/OfferPage.vue"
import PolicyPage from "@/views/Info/PolicyPage.vue"
import RegisterPage from "@/views/Auth/RegisterPage.vue"
import UserInfoPage from "@/views/UserInfoPage.vue"
import UserSettings from "@/views/UserSettings.vue"
import UserUpdatePage from "@/views/UserUpdatePage.vue"
import TariffsPage from "@/views/Info/TariffsPage.vue"
import UslugaPage from "@/views/Info/UslugaPage.vue"
import KontaktPage from "@/views/Info/KontaktPage.vue"
import ZakazPage from "@/views/Info/ZakazPage.vue"
import ConditionsReferralsPage from "@/views/ConditionsReferralsPage.vue";
import AvitoMonitoringTasksPage from "@/views/AvitoMonitoring/AvitoMonitoringTasksPage.vue";
import AvitoAuthPage from "@/views/AvitoAuthPage.vue";
import TableCreateGapiAuthPage from "@/views/TableCreateGapiAuthPage.vue";
import TablesPage from "@/views/TablesPage.vue";
import MessengersPage from "@/views/MessengersPage.vue";
import ArchivedUsersPage from "@/views/ArchivedUsersPage.vue";
import AdministratorsUsersPage from "@/views/AdministratorsUsersPage.vue";
import UsersPage from "@/views/UsersPage.vue";
import AboutPage from "@/views/Info/AboutPage.vue";
import ListOfServicesPage from "@/views/Info/ListOfServicesPage.vue";
import DateGeneratorPage from "@/views/Tools/DateGeneratorPage.vue";
import CoordinatesGeneratorPage from "@/views/Tools/CoordinatesGeneratorPage.vue";
import LogsPage from "@/views/System/LogsPage.vue";
import store from '@/store/index'
import VideosPage from "@/views/VideosPage.vue";
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import TagsAvitoPage from "@/views/TagsAvitoPage.vue";
import SystemSettings from "@/views/SystemSettingsPage.vue";
import SmsSend from "@/views/SmsSendPage.vue";
import SmsTemplates from "@/views/SmsTemplatesPage.vue";
import AvitoRepubPage from "@/views/AvitoRepubPage.vue";
import AvitoRepubPage_v2 from "@/views/AvitoRepubPage_v2.vue";
import AvitoRepubHistoryPage_v2 from "@/views/AvitoRepubHistoryPage_v2.vue";
import AvitoServicesPage from "@/views/AvitoServicesPage.vue";
import ImagesTransformPage from "@/views/ImagesTransformPage.vue";

Vue.use(VueRouter)
const root = store.state.root;

const routes: Array<RouteConfig> = [
    {
        path: root + '',
        name: 'home',
        component: HomePage,
    },
    {
        path: root + 'autoload',
        name: 'autoload',
        component: TablesPage
    },
    {
        path: root + 'avito-repub',
        name: 'avito-repub',
        component: AvitoRepubPage
    },
    {
        path: `${root}avito-repub-v2/:table_id`,
        name: 'avito-repub-v2',
        component: AvitoRepubPage_v2
    },
    {
        path: `${root}avito-repub-history-v2/:rule_id`,
        name: 'avito-repub-history-v2',
        component: AvitoRepubHistoryPage_v2
    },
    {
        path: `${root}images-transform/:table_id`,
        name: 'images-transform',
        component: ImagesTransformPage
    },
    {
        path: root + 'avito-services',
        name: 'avito-services',
        component: AvitoServicesPage
    },
    {
        path: root + 'messengers',
        name: 'messengers',
        component: MessengersPage
    },
    {
        path: root + 'marketplaces',
        name: 'marketplaces',
        component: MarketplacesPage
    },
    {
        path: root + 'users',
        name: 'users',
        component: UsersPage,
    },
    {
        path: root + 'tags/avito',
        name: 'tags/avito',
        component: TagsAvitoPage,
    },
    {
        path: root + 'system/settings',
        name: 'systemSettings',
        component: SystemSettings,
    },
    {
        path: root + 'sms/send',
        name: 'sms/send',
        component: SmsSend,
    },
    {
        path: root + 'sms/templates',
        name: 'sms/templates',
        component: SmsTemplates,
    },
    {
        path: root + 'users/archived',
        name: 'users/archived',
        component: ArchivedUsersPage,
    },
    {
        path: root + 'users/admins',
        name: 'users/admins',
        component: AdministratorsUsersPage,
    },
    {
        path: root + 'user/info',
        name: 'userInfo',
        component: UserInfoPage
    },
    {
        path: root + 'settings',
        name: 'settings',
        component: UserSettings
    },
    {
        path: root + 'register',
        name: 'register',
        component: RegisterPage
    },
    {
        path: root + 'login',
        name: 'login',
        component: LoginPage
    },
    {
        path: root + 'passwordForgot',
        name: 'passwordForgot',
        component: PasswordForgotPage
    },
    {
        path: root + 'passwordReset',
        name: 'passwordReset',
        component: PasswordResetPage
    },
    {
        path: root + 'passwordSent',
        name: 'passwordSent',
        component: PasswordSentPage
    },
    {
        path: root + 'verifyEmail/:id/:hash',
        name: 'verifyEmail',
        component: VerifyEmailPage
    },
    {
        path: root + 'user/update',
        name: 'userUpdate',
        component: UserUpdatePage
    },

    {
        path: root + 'help',
        name: 'help',
        component: HelpPage
    },
    {
        path: root + 'help/:article',
        name: 'helpDetail',
        component: HelpDetailPage
    },

    {
        path: root + 'finances',
        name: 'finances',
        component: FinancesPage
    },
    {
        path: root + 'finances/referrals',
        name: 'finances/referrals',
        component: FinancesReferralsPage
    },
    {
        path: root + 'finances/invitations',
        name: 'finances/invitations',
        component: FinancesInvitationsPage
    },
    {
        path: root + 'invitations/:hash',
        name: 'invitations',
        component: InvitationsPage
    },
    {
        path: root + 'finances/statistics',
        name: 'finances/statistics',
        component: FinancesStatisticsPage
    },

    {
        path: root + 'about',
        name: 'about',
        component: AboutPage
    },
    {
        path: root + 'tools/date-generator',
        name: 'tools/dateGenerator',
        component: DateGeneratorPage
    },
    {
        path: root + 'tools/coordinates-generator',
        name: 'tools/coordinatesGenerator',
        component: CoordinatesGeneratorPage
    },
    {
        path: root + 'policy',
        name: 'policy',
        component: PolicyPage
    },
    {
        path: root + 'listofservices',
        name: 'listofservices',
        component: ListOfServicesPage
    },
    {
        path: root + 'offer',
        name: 'offer',
        component: OfferPage
    },
    {
        path: root + 'tariffs',
        name: 'tariffs',
        component: TariffsPage
    },
    {
        path: root + 'usluga',
        name: 'usluga',
        component: UslugaPage
    },
    {
        path: root + 'kontakt',
        name: 'kontakt',
        component: KontaktPage
    },
    {
        path: root + 'zakaz',
        name: 'zakaz',
        component: ZakazPage
    },
    {
        path: root + 'conditionsReferrals',
        name: 'conditionsReferrals',
        component: ConditionsReferralsPage
    },
    {
        path: root + 'videos',
        name: 'videos',
        component: VideosPage
    },
    {
        path: root + 'monitoring/tasks/:projectid',
        name: 'AvitoMonitoringTasks',
        component: AvitoMonitoringTasksPage
    },
    {
        path: root + 'avito-auth',
        name: 'avito-auth',
        component: AvitoAuthPage
    },
    {
        path: root + 'table-create-gapi-auth',
        name: 'table-create-gapi-auth',
        component: TableCreateGapiAuthPage
    },
    {
        path: root + 'system/logs',
        name: 'system/logs',
        component: LogsPage,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
