import { UserRoles } from "@/enums/enums"
import RootState from "@/interfaces/RootState"
import UsersState from "@/interfaces/UsersState"
import User from "@/interfaces/User"
import router from "@/router"
import { ErrorResponse, UsersActionContext } from "@/types/types"
import axios, { AxiosResponse } from "axios"
import {ActionContext, Module} from "vuex"
import TransactionsState from "@/interfaces/TransactionsState";
import AdminUser from "@/interfaces/AdminUser";

const Users: Module<UsersState, RootState> = {
    namespaced: true,
    state: {
        usersTable: [] as User[],
        adminUsers: [] as AdminUser[],
        userInfo: {} as User,
        isBlocked: false
    },
    mutations: {
        SET_USER_INFO(state: UsersState, userInfo: User): void {
            state.userInfo = userInfo
        },
        SET_USER_IS_BLOCKED(state: UsersState, payload: true): void {
            state.isBlocked = payload
        },
        SET_USERS_TABLE(state: UsersState, userTables: User[]): void {
            state.usersTable = userTables
        },
        SET_ADMIN_USERS(state: UsersState, adminUsers: AdminUser[]): void {
            state.adminUsers = adminUsers
        },
        SORT_USERS_TABLE_BY(
            state: UsersState,
            sort: keyof User
        ): void {
            state.usersTable.sort((a: User, b: User) => {
                const bElement = b[sort];
                const aElement = a[sort];
                return ((bElement ? bElement : 0) > (aElement ? aElement : 0)) ? -1 : 1;
            })
        },
    },
    actions: {
        async setUsers({ commit, dispatch, rootState, rootGetters }: UsersActionContext): Promise<void> {
            const url = await dispatch('getFullUrl', 'users', { root: true });
            await axios.get<User[]>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<User[]>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_USERS_TABLE', res.data)
                            commit('SORT_USERS_TABLE_BY', 'userId')
                            rootState.hashValidated = true;
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        async setAdminUsers({ commit, dispatch, rootState, rootGetters }: UsersActionContext): Promise<void> {
            const url = await dispatch('getFullUrl', 'users/admins', { root: true });
            await axios.get<User[]>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then<void, never>((res: AxiosResponse<User[]>) => {
                    const statusCode = res.request.status
                    switch (statusCode) {
                        case 200:
                            commit('SET_ADMIN_USERS', res.data)
                            rootState.hashValidated = true;
                            break
                        default:
                            commit('SET_MSG_FROM_STATUS_CODE', statusCode, { root: true })
                    }
                })
                .catch(err => console.error(err))
        },

        async redirectIfNotAdmin({ getters, dispatch }: UsersActionContext): Promise<boolean> {
            if (getters['userIsAdmin']) {
                return false
            }

            await dispatch('redirectToHome', null, { root: true })
            return true
        },

        async redirectIfNotAdminLite({ getters, dispatch }: UsersActionContext): Promise<boolean> {
            if (getters['userIsAdmin'] || getters['userIsAdminLite']) {
                return false
            }

            await dispatch('redirectToHome', null, { root: true })
            return true
        },

        async redirectIfNotAdminOrManager({ getters, dispatch }: UsersActionContext): Promise<boolean> {
            if (getters['userIsAdmin'] || getters['userIsAdminLite'] || getters['userIsManager']) {
                return false
            }

            await dispatch('redirectToHome', null, { root: true })
            return true
        },

        redirectIfNoUserInfo({ dispatch, getters }: UsersActionContext): void {
            if (router.currentRoute.name === 'userInfo') return

            if (getters.hasUserInfo) {
                return
            }

            dispatch('redirectToUserInfo')
        },

        redirectToUserInfo({ rootState, rootGetters }: UsersActionContext): void {
            const query = rootGetters.hasHash ? { hash: rootState.hash } : {};

            router.push({ name: 'userInfo', query })
                .catch(err => console.error(err))
        },

        async setUserInfo(
            { commit, dispatch, rootState, rootGetters }: UsersActionContext
        ): Promise<void> {
            const url = await dispatch('getFullUrl', 'users/myAccount', { root: true });
            await axios.get<User>(
                url + rootGetters.hashQuery,
                { validateStatus: (): boolean => true }
            )
                .then((res: AxiosResponse<User>) => {
                    const statusCode = res.request.status
                    if (statusCode === 200) {
                        commit('SET_USER_INFO', res.data)
                        rootState.hashValidated = true
                    }
                    if (statusCode === 403) {
                        dispatch('setIsBlockedIfNeeded', res)
                    }
                })
                .catch(err => console.error(err))
        },

        setIsBlockedIfNeeded(
            { commit }: UsersActionContext,
            res: AxiosResponse<ErrorResponse>
        ): void {
            if (res.data.error && res.data.error.code && res.data.error.code === 'BLOCKED') {
                commit('SET_USER_IS_BLOCKED', true)
            }
        },

        logout({ commit }: UsersActionContext): void {
            commit('SET_USER_INFO', {} as User)
        }
    },
    getters: {
        getUsersTable(state: UsersState): User[] {
            return state.usersTable
        },
        getAdminUsers(state: UsersState): AdminUser[] {
            return state.adminUsers
        },
        hasUser(state: UsersState): boolean {
            return !!state.userInfo.userId
        },
        isUserBlocked(state: UsersState): boolean {
            return state.isBlocked
        },
        hasUserInfo(state: UsersState): boolean {
            const { name } = state.userInfo
            return !!(name)
        },
        getUser(state: UsersState): User {
            return state.userInfo
        },
        userIsPartner(state: UsersState): boolean {
            return (state.userInfo.isPartner)
        },
        userIsAdmin(state: UsersState): boolean {
            return (state.userInfo.roleId === UserRoles.admin)
        },
        userIsAdminLite(state: UsersState): boolean {
            return (state.userInfo.roleId === UserRoles.adminLite)
        },
        userIsManager(state: UsersState): boolean {
            return (state.userInfo.roleId === UserRoles.manager)
        },
        userIsService(state: UsersState): boolean {
            return (state.userInfo.roleId === UserRoles.service)
        },
        hasEmail(state: UsersState): boolean {
            return !!state.userInfo.email
        },
        hasVerifyEmail(state: UsersState): boolean {
            return state.userInfo.hasVerifyEmail
        },
        surveysCompletedList(state: UsersState): string[]{
            return state.userInfo.surveysCompleted
        }
    }
}

export default Users
