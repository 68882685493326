
import { Vue, Component } from "vue-property-decorator";
import AvitoRepubTableListComponent_v2 from "@/components/TableList/AvitoRepubTableListComponent_v2.vue";
import TableColumn from "@/interfaces/TableColumn";
import TDAddRepubRule_v2 from "@/components/TableList/TableData/TDAddRepubRule_v2.vue";
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";

@Component({
    components: {
        AvitoRepubTableListComponent_v2,
        TDAddRepubRule_v2
    }
})
export default class AvitoRepubPage_v2 extends Vue {
    columns: TableColumn[] = [];
    currentPage = 1;
    perPage = 5;
    perPageVariants = [
        { active: true, perPage: 5 },
        { active: false, perPage: 10 },
        { active: false, perPage: 50 },
        { active: false, perPage: 100 }
    ];
    searchedText = "";
    filterText = "";
    helpModalVisible = false;
    youtubeModalVisible = false;
    loading = false;
    timeout?: number;

    get icon(): string {
        return this.loading ? "arrow-clockwise" : "search";
    }

    get animation(): string {
        return this.loading ? "spin" : "";
    }

    get rows(): number {
        return this.filteredRules.length;
    }

    get rules(): AvitoRepubRule_v2[] {
        return this.$store.getters["AvitoRepub_v2/getAvitoRepubRules"];
    }

    get selectedTableId(): number | null {
        return this.$store.getters["AvitoRepub_v2/getSelectedTableId"];
    }

    set selectedTableId(tableId: number | null) {
        this.$store.commit("AvitoRepub_v2/SET_SELECTED_TABLE_ID", tableId);
    }

    get filteredRules(): AvitoRepubRule_v2[] {
        let filteredRules = this.rules;

        if (this.filterText !== "") {
            filteredRules = filteredRules.filter((item): boolean => {
                if (!item) {
                    return false;
                }

                const { cities, titles, cities_exclude, titles_exclude, ids_exclude, sheets_exclude } = item;

                const citiesStr = cities.join(' ') + ' ' + cities_exclude.join(' ');
                const titlesStr = titles.join(' ') + ' ' + titles_exclude.join(' ');
                const idsStr = ids_exclude.join(' ');
                const sheetsStr = sheets_exclude.join(' ');

                return (
                    (!!citiesStr && citiesStr.toLowerCase().indexOf(this.filterText) !== -1)
                    || (!!titlesStr && titlesStr.toLowerCase().indexOf(this.filterText) !== -1)
                    || (!!idsStr && idsStr.toLowerCase().indexOf(this.filterText) !== -1)
                    || (!!sheetsStr && sheetsStr.toLowerCase().indexOf(this.filterText) !== -1)
                );
            });
        }

        if (this.selectedTableId !== null) {
            filteredRules = filteredRules.filter(
                (item) => item.table_id === this.selectedTableId
            );
        }

        return filteredRules;
    }


    search(): void {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            setTimeout(() => (this.loading = true));

            setTimeout(() => {
                this.filterText = this.searchedText.trim().toLowerCase();

                this.loading = false;
            });
        }, 1000);
    }


    showHelp(): void {
        const link = 'https://docs.google.com/document/d/1rgM8idSSKdymkwv8XiIOsFLous4uikVZDqKBX-sJC0g';
        window.open(link, '_blank');
    }

    showYoutube(): void {
        const link = 'https://youtu.be/DclCpRDW7RY?si=DoSDsOsc65HW2F1y';
        window.open(link, '_blank');
    }

    async created(): Promise<void> {
        document.title = "Правила перепубликации объявлений Авито 2.0";

        if (!this.$store.getters["Users/hasUser"]) {
            return;
        }

        this.$store.state.alert = "";
        this.$store.state.msg = "";

        this.$store.commit("AvitoRepub_v2/SET_SELECTED_TABLE_ID", this.$route.params.table_id);
        this.selectedTableId = parseInt(this.$route.params.table_id);

        this.$store.commit("SET_LOADING", true);
        await Promise.all([
            this.$store.dispatch("AvitoRepub_v2/setAvitoRepubRules")
        ]).finally(() => this.$store.commit("SET_LOADING", false));
    }


    addNewRule(id): void {
        if (id === 0) {
            this.currentPage = Math.ceil(this.rows / this.perPage);
        }
    }


    changePaginate(variant: { active: boolean; perPage: number }): void {
        this.perPageVariants.forEach((variant) => (variant.active = false));
        this.perPage = variant.perPage;
        variant.active = true;
    }
}
