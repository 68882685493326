
import Generator from "@/interfaces/Generator"
import axios, { AxiosResponse } from "axios";
import { BvModal, BvModalEvent } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator"
import moment from "moment";

@Component
export default class PurchaseMaxAds extends Vue {
    $refs!: {
        form: HTMLFormElement
    }
    $bvModal!: BvModal

    loading = false
    showSubscribe = false
    subscribe = false
    nextMonth = 'no'

    generator = {} as Generator
    dateExpired = 0
    price = 0
    priceNextMonth = 0

    get showNextMonthOption(): boolean {
        return this.dateExpired > Math.floor(Date.now() / 1000)
    }

    get showText(): boolean {
        return !this.loading
    }

    get textThisMonth(): string {
        return 'В этот период до '+this.formatDateExpired+'?<br>'+this.getText(this.price)
    }

    get textNextMonth(): string {
        return 'В следующий период после '+this.formatDateExpired+'?<br>'+this.getText(this.priceNextMonth)
    }

    get formatDateExpired(): string {
        return moment.unix(this.dateExpired).format('YYYY-MM-DD')
    }

    get isTestTable(): boolean {
        return this.$store.getters['Generators/getSelectedOriginalMaxAds'] < 500
    }

    getText(price: number): string {
        return 'С вашего баланса спишется сумма в <span class="text-my-primary">' + price +
            '</span> монет!'
    }

    resetModal(): void {
        this.generator = this.$store.getters['Generators/getSelectedGenerator']
        this.showSubscribe = !this.isTestTable
        this.subscribe = this.$store.getters['Generators/getSelectedSubscribed']
        this.dateExpired = this.$store.getters['Generators/getSelectedDateExpired']

        this.nextMonth = 'no'

        if (this.loading) {
          return
        }
        this.loading = true

        Promise.all([
            this.getPrice(),
            this.getPrice(true)
        ])
            .finally(() => this.loading = false)
    }

    async getPrice(nextMonth = false): Promise<void> {
        if (!nextMonth && !this.showNextMonthOption) {
            return
        }

        const { targetPlatform, maxAds, generatorGuid } = this.generator

        const url = await this.$store.dispatch('getFullUrl', 'prices');
        await axios.post<number>(
            url,
            {
                targetPlatform,
                maxAds,
                generatorGuid,
                nextMonth
            },
            { validateStatus: (): boolean => true }
        )
            .then((res) => {
                const statusCode = res.request.status

                switch (statusCode) {
                    case 200:
                        if (nextMonth) {
                            this.priceNextMonth = res.data
                            break
                        }
                        this.price = res.data
                        break
                    case 400:
                        this.$bvModal.hide('purchase_max_ads_modal')
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    hiddenModal(): void {
        this.$store.dispatch('Generators/resetSelected')
        this.price = 0
        this.priceNextMonth = 0
        //this.subscribe = this.$store.getters['Generators/getSelectedSubscribed']
    }

    handleOk(bvModalEvt: BvModalEvent): void {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.purchase()
    }

    async purchase(): Promise<void> {
        if (this.loading) {
            return
        }
        this.loading = true;

        const generator = this.generator
        const { targetPlatform, maxAds, generatorGuid, subscribed } = generator
        const tableId = this.$store.getters['Generators/getSelectedTableId']
        const nextMonth = (this.nextMonth === 'yes')

        const url = await this.$store.dispatch('getFullUrl', 'transactions/maxAds');
        await axios.post<number | false>(
            url,
            {
                targetPlatform,
                maxAds,
                generatorGuid,
                subscribe: subscribed,
                nextMonth
            },
            { validateStatus: (): boolean => true }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        if (nextMonth) {
                            this.$store.dispatch('Generators/updateSubscribedMaxAdsSelected', maxAds)
                            this.$store.dispatch('Generators/updateSubscribedSelected', true)
                            this.$bvModal.hide('purchase_max_ads_modal')
                            break
                        }
                        if (res.data !== false) {
                            this.$bvModal.msgBoxOk(
                                'Успешно выполнена смена тарифа.',
                                {
                                    centered: true
                                }
                            )
                            this.generator.subscribed = subscribed
                            this.$store.dispatch('Generators/setSelected', {
                                generator,
                                originalMaxAds: maxAds,
                                tableId,
                                dateExpired: res.data
                            })
                            this.$store.commit('UPDATE_DATE_EXPIRED', { tableId, dateExpired: res.data })
                            this.$store.dispatch('Wallet/setBalance')
                        } else {
                            this.$bvModal.msgBoxOk(
                                'На вашем балансе недостаточно средств!',
                                {
                                    centered: true,
                                    bodyTextVariant: 'danger'
                                }
                            )
                        }
                        this.$bvModal.hide('purchase_max_ads_modal')
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
    }
}
