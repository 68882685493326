<template>
    <div class="d-flex flex-column justify-content-center">
        <div class="event text-center" @click="openModal">
            <img :src="getImageSource()" alt="Баннер мероприятия" class="responsive-image">
        </div>

        <b-modal size="sm" v-model="isModalOpen" hide-header hide-footer class="modal-form">
            <div v-if="!submissionSuccess">
                <div :style="{ backgroundImage: 'url(' + this.$store.state.root + 'assets/event/form-bg.png)' }">
                    <form @submit.prevent="submitForm" class="event-form p-3" :class="{ 'is-loading': loading }">
                        <div class="header">Забронируйте место на "Прокачка Бизнеса 2.0"</div>
                        <img :src="this.$store.state.root + 'assets/event/sale20.png'" alt="sale 20" class="mt-3">

                        <b-form-input v-model="name" placeholder="Имя и фамилия" class="mt-4"></b-form-input>

                        <b-form-input v-model="email" placeholder="Email" class="mt-2"></b-form-input>

                        <b-form-input v-model="phone" placeholder="Номер телефона" class="mt-2"></b-form-input>

                        <b-form-input v-model="telegram" placeholder="Ник Telegram" class="mt-2"></b-form-input>

                        <div v-if="error" class="error text-small text-danger mt-2">{{ error }}</div>

                        <b-button type="submit" variant="primary" class="mt-4">Получить программу мероприятия</b-button>
                    </form>
                </div>
            </div>
            <div v-else>
                <img :src="this.$store.state.root + 'assets/event/success.png'" alt="Успешно">
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            isModalOpen: false,
            name: '',
            email: '',
            phone: '',
            telegram: '',
            error: null,
            loading: false,
            submissionSuccess: false,
        };
    },
    methods: {
        getImageSource() {
            const screenWidth = window.innerWidth;

            if (screenWidth >= 1920) {
                return this.$store.state.root + 'assets/event/1920.png';
            } else if (screenWidth >= 1280 && screenWidth < 1920) {
                return this.$store.state.root + 'assets/event/1280.png';
            } else if (screenWidth >= 768 && screenWidth < 1280) {
                return this.$store.state.root + 'assets/event/768.png';
            }else {
                return this.$store.state.root + 'assets/event/mobile.png';
            }
        },
        openModal() {
            this.isModalOpen = true;
        },
        submitForm() {
            const formData = {
                name: this.name,
                email: this.email,
                phone: this.phone,
                telegram: this.telegram
            };

            this.error = null;
            this.loading = true;

            axios.post('https://api.adviz.pro/api/event/reg', formData)
                .then(response => {
                    this.submissionSuccess = true;
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.error) {
                        this.error = 'Проверьте, что все поля заполнены корректно';
                    } else {
                        this.error = 'Произошла ошибка при отправке данных';
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.event {
    width: 100%;
    background-color: #171717;
    cursor: pointer;
}

.event-form .header {
    color: white;
    font-weight: bolder;
    line-height: 1.1rem;
}

.event-form input {
    background-color: #232323;
    border-color: #363636;
    color: #5A5A5A;
    font-size: 13px;
}

.event-form button {
    background-color: #30B271;
    border-color: #30B271;
    border-radius: 20px;
    color: black;
    font-weight: bold;
}

.event-form button:hover {
    background-color: #30B271;
    border-color: #30B271;
    box-shadow: 0 4px 72px 0 #30B271;
}

.error {
    font-size: 14px;
    line-height: 1rem;
}

.is-loading {
    opacity: 0.7;
    pointer-events: none;
}

</style>
