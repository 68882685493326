
import {Component, Vue, Prop} from "vue-property-decorator"
import TableColumn from "@/interfaces/TableColumn"
import TableDataComponent from "@/components/TableList/TableDataComponent.vue"
import AvitoRepubRule from "@/interfaces/AvitoRepubRule";
import AvitoRepubRule_v2 from "@/interfaces/AvitoRepubRule_v2";
import AvitoRepubHistory_v2 from "@/interfaces/AvitoRepubHistory_v2";
import AvitoServicesRule from "@/interfaces/AvitoServicesRule";
import TableItem from "@/interfaces/TableItem";
import User from "@/interfaces/User";
import {adminTransaction, transaction} from "@/types/transactions";
import {invitation} from "@/types/invitations";
import {referral} from "@/types/referrals";
import Tag from "@/interfaces/Tag";

@Component({
    components: {
        TableDataComponent,
    }
})
export default class TableListComponent extends Vue {
    @Prop() index!: number;
    @Prop() item!: TableItem | User | transaction | adminTransaction | invitation | referral | Tag | AvitoRepubRule | AvitoRepubRule_v2 | AvitoRepubHistory_v2 | AvitoServicesRule;
    @Prop() columns!: TableColumn[];
}
