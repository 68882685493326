import { render, staticRenderFns } from "./TableDataComponent.vue?vue&type=template&id=63edc97a&scoped=true"
import script from "./TableDataComponent.vue?vue&type=script&lang=ts"
export * from "./TableDataComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63edc97a",
  null
  
)

export default component.exports