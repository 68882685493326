
import { maxAdsSelectOption, maxAdsSelectOptions } from "@/types/generators"
import axios, { AxiosResponse } from "axios"
import { BvModal, BvModalEvent } from "bootstrap-vue"
import { Component, Vue, Prop } from "vue-property-decorator"
import Generator from "@/interfaces/Generator"
import TableItem from "@/interfaces/TableItem";

@Component
export default class TDMaxAds extends Vue {
    @Prop() item!: TableItem;

    static componentName = "TDMaxAds"

    $bvModal!: BvModal

    loading = false
    originalMaxAds = {} as { [key: string]: number }
    options = {} as maxAdsSelectOptions

    price = 0
    priceNextMonth = 0
    priceSubscribe = 0

    get generators(): Generator[] {
        return this.item.generators ? this.item.generators : []
    }

    get generator(): Generator | null {
        const generator = this.generators
            .find((generator: Generator): boolean => {
                return generator.targetPlatform === 'Avito' ||
                    generator.targetPlatform === 'OZON' // marketplaces
            })
        if (!generator) {
            return null
        }

        return generator
    }

    get maxAds(): number {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.generator.subscribedMaxAds || this.generator.maxAds
    }

    tariffName(maxAds: number): string {
        const selectOptions = this.$store.getters['Generators/getMaxAdsSelectOptions']
        const tariffList = selectOptions['Avito']

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let result = tariffList.find((item) => {
            return item.value == maxAds;
        });

        return result ? result.text : 'до ' + maxAds;
    }

    get message(): string {
        if (!this.generator) {
            return ''
        }

        const head = this.generator.subscribed ? 'Включить автопродление?' : 'Отключить автопродление?'

        return head +
            '<br><br>' +
            'Тариф ' +
            '<span class="text-my-primary">' + this.tariffName(this.maxAds) + '</span> ' +
            ' за <span class="text-my-primary">' + this.priceSubscribe + '</span> монет!' +
            '<br><br>' +
            '<span class="h5 text-danger">Важно, прочитайте до конца!</span><br>' +
            'Автопродление не подразумевает автоматическое списывание денежных средств с вашей карты. Списываться будут средства, зачисленные ранее на баланс вашего личного кабинета!<br>' +
            'Если хотите, чтобы баланс автоматически пополнялся перед автопродлением таблицы, настройте <strong>Автоплатеж</strong> в разделе «Финансы». Иначе необходимо будет заранее пополнять баланс вручную 🙂'
    }

    get showSubscribe(): boolean {
        if (!this.generator) {
            return false
        }

        // marketplaces
        if (this.generator.targetPlatform === 'OZON') {
            return false
        }

        return (this.generator.maxAds >= 500)// && (this.item.dateExpired > Math.floor((Date.now() / 1000)))
    }

    created(): void {
        const selectOptions = this.$store.getters['Generators/getMaxAdsSelectOptions']

        this.generators.forEach((generator: Generator) => {
            const { targetPlatform, maxAds } = generator
            this.originalMaxAds[targetPlatform] = maxAds
            this.options[targetPlatform] = this.getOptions(generator, selectOptions)
        })
    }

    getOptions(generator: Generator, selectOptions: maxAdsSelectOptions): maxAdsSelectOption[] {
        const { maxAds, targetPlatform } = generator
        const options = selectOptions[targetPlatform]

        if (options) {
            const originalIndex = options.findIndex((option: maxAdsSelectOption): boolean => option.value === maxAds)
            if (originalIndex === -1) {
                return [{text: maxAds, value: maxAds, discount: 0}, ...options]
            }
        }

        return options
    }

    showGeneratorModal(generator: Generator): void {
        const originalMaxAds = this.originalMaxAds[generator.targetPlatform]
        const tableId = this.item.tableId
        const dateExpired = this.item.dateExpired
        this.$store.dispatch('Generators/setSelected', { generator, originalMaxAds, tableId, dateExpired })

        this.$bvModal.show('purchase_max_ads_modal')
    }

    showSubscribeModal(generator: Generator): void {
        generator.subscribed = !generator.subscribed
        this.$bvModal.show('subscribe_modal' + generator.generatorGuid)
    }

    resetModal(): void {
        if (!this.generator) {
            return
        }

        Promise.all([
            this.getPrice(true, true)
        ])
            .finally(() => this.loading = false)

        this.generator.subscribed = !this.generator.subscribed
    }

    async getPrice(nextMonth = false, forSubscribe = false): Promise<void> {
        if (!nextMonth) {
            return
        }

        const { targetPlatform, maxAds, subscribedMaxAds, generatorGuid } = this.generator as Generator

        const url = await this.$store.dispatch('getFullUrl', 'prices');
        await axios.post<number>(
            url,
            {
                targetPlatform,
                maxAds: forSubscribe ? (subscribedMaxAds ? subscribedMaxAds : maxAds) : maxAds,
                generatorGuid,
                nextMonth
            },
            { validateStatus: (): boolean => true }
        )
            .then((res) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        if (nextMonth) {
                            this.priceSubscribe = res.data
                            break
                        }
                        if (nextMonth) {
                            this.priceNextMonth = res.data
                            break
                        }
                        this.price = res.data
                        break
                    case 400:
                        this.$bvModal.hide('purchase_max_ads_modal')
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
    }

    hiddenModal(): void {
        if (!this.generator) {
            return
        }

        this.price = 0
        this.priceNextMonth = 0
        this.priceSubscribe = 0
        this.generator.subscribed = !this.generator.subscribed
    }

    handleOk(bvModalEvt: BvModalEvent): void {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.subscribe()
    }

    async subscribe(): Promise<void> {
        if (!this.generator) {
            return
        }
        if (this.loading) {
            return
        }
        this.loading = true;

        const generator = this.generator
        const { generatorGuid, subscribed } = generator
        const subscribe = subscribed

        const url = await this.$store.dispatch('getFullUrl', 'transactions/subscribe');
        await axios.post<number | false>(
            url,
            {
                generatorGuid,
                subscribe
            },
            { validateStatus: (): boolean => true }
        )
            .then((res: AxiosResponse) => {
                const statusCode = res.request.status
                switch (statusCode) {
                    case 200:
                        this.$bvModal.msgBoxOk(
                            'Успешно.',
                            {
                                centered: true
                            }
                        )
                        generator.subscribed = !generator.subscribed
                        if (!subscribe) {
                            generator.subscribedMaxAds = null
                        }
                        this.$bvModal.hide('subscribe_modal' + generator.generatorGuid)
                        break
                    default:
                        this.$store.commit('SET_MSG_FROM_STATUS_CODE', statusCode)
                }
            })
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
    }
}
